/* Navbar.css */

/* 导航链接样式 */
.nav-link {
  color: #1a1a1a;
  font-size: 16px;
  text-decoration: none;
  position: relative;
  padding: 8px 0;
  font-weight: 400;
  transition: color 0.2s;
}

.nav-link:hover {
  color: #000;
}

.nav-link.active {
  color: #000;
  font-weight: 500;
}

/* 登录按钮悬停效果 */
.login-button {
  transition: all 0.3s ease !important;
}

.login-button:hover {
  background-color: #000 !important;
  border-color: #000 !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

/* 控制台按钮悬停效果 */
.dashboard-button {
  border-color: #1a1a1a;
  color: #1a1a1a;
  transition: all 0.3s ease;
  border-radius: 6px !important;
  height: 44px !important;
  padding: 0 24px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.dashboard-button:hover {
  background-color: #f0f0f0 !important;
  border-color: #000 !important;
  color: #000 !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

/* 文档按钮悬停效果 */
.docs-button {
  background-color: #1a1a1a !important;
  border-color: #1a1a1a !important;
  transition: all 0.3s ease;
  border-radius: 6px !important;
  height: 44px !important;
  padding: 0 24px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.docs-button:hover {
  background-color: #000 !important;
  border-color: #000 !important;
  transform: translateY(-2px) !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2) !important;
} 