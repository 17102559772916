body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #1a1a1a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 覆盖Ant Design默认样式 */
.ant-btn-primary {
  background-color: #1a1a1a;
  border-color: #1a1a1a;
}

.ant-btn-primary:hover, 
.ant-btn-primary:focus {
  background-color: #333;
  border-color: #333;
}

.ant-input, 
.ant-select-selector {
  border-radius: 8px !important;
}

.ant-form-item-label > label {
  font-weight: 500;
}

h1, h2, h3, h4, h5 {
  font-weight: 500;
}

/* 自定义链接样式 */
a {
  color: #1a1a1a;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* 去除页面底部空白区域 */
.ant-tabs-content-holder {
  flex: 1;
  overflow: hidden;
}

.ant-tabs-content {
  height: 100%;
}

.ant-tabs-tabpane {
  height: 100%;
}

/* 确保Playground页面占满整个区域 */
.playground-tabs {
  height: 100%;
}

.playground-tabs .ant-tabs-content {
  padding-bottom: 0;
  margin-bottom: 0;
}

/* 修复底部可能的边距问题 */
.ant-card {
  margin-bottom: 0;
}

/* 确保内容区域不会超出容器 */
.ant-tabs-tabpane-active {
  padding-bottom: 0 !important;
  overflow-y: auto;
}

/* 修改标签页的悬停样式，去除蓝色 */
.playground-tabs .ant-tabs-tab:hover {
  color: #333 !important;
}

.playground-tabs .ant-tabs-tab-active:hover .ant-tabs-tab-btn {
  color: #1a1a1a !important;
}

.playground-tabs .ant-tabs-ink-bar {
  background-color: #1a1a1a !important;
}
